document.addEventListener("DOMContentLoaded", function() {
    $('[data-toggle="tooltip"]').tooltip({
        html: true
    });
    
    // Forgot password username assistance
    var forgotLink = document.getElementById('forgot-password');
    if (forgotLink) {
        forgotLink.addEventListener('click', function(e){
            var username = document.getElementById('user-username').value;
            if (username) {
                const d = new Date();
                d.setTime(d.getTime() + (60 * 1000)); /* 1 min */
                var expires = "expires = " + d.toUTCString();
                document.cookie = "forgotAccount = " + username + ";" + expires + "; path=/; secure";
            }
        });
    }
});